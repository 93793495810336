import React from 'react'

function GoogleButton() {
  return (
    <div className="google-btn">
        <div className="google-icon-wrapper">
            <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
        </div>
        <p className="btn-text"><b>Google Sign In</b></p>
    </div>
  )
}

export default GoogleButton